/* eslint-disable */
<template>
  <div>
    <DarkModeSwitcher />
    <MobileMenu />
    <div class="flex">
      <!-- BEGIN: Side Menu -->
      <nav class="side-nav" :hidden="!menuData">
        <!-- BEGIN: Logo -->
        <router-link
          :to="{ name: 'manager-dashboard' }"
          tag="a"
          class="intro-x flex items-center pl-5 pt-4"
        >
          <img
            alt="Icewall Tailwind HTML Admin Template"
            class=""
            src="@/assets/images/bm/kodllin_main.png"
          />
          <!-- <span class="hidden xl:block text-white text-lg ml-3">
            Blue<span class="font-medium">Moon</span>
          </span> -->
        </router-link>
        <!-- END: Logo -->
        <!-- <div class="side-nav__devider my-6"></div>
          <button class="btn btn-link" @click="collapseMenu">Collaps</button>-->
        <div class="side-nav__devider my-6"></div>
        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li
              v-if="menu == 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-2"
            ></li>
            <li v-else :key="menu + menuKey">

              <div v-if="menu.roles.includes(userDetails.user_role) || (menu.pageName == 'outword-packets-view' && userDetails.outward == true)">

              <SideMenuTooltip
                tag="a"
                :content="menu.title"
                href="javascript:;"
                class="side-menu mb-0"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                @click="linkTo(menu, router, $event)"
              >
                <div class="side-menu__icon">
                  <component :is="menu.icon" />
                </div>
                <div class="side-menu__title p-0 m-0">
                  {{ menu.title }}
                  <span
                  class="text-xl px-1 font-bold text-theme-6 mr-1 ml-2 pt-2"
                  v-if="menu.title == 'Process' && (screenData.reqCount > 0 || screenData.reqCountLot > 0)"
                  > *</span
                >
                  <div
                    v-if="menu.subMenu"
                    class="side-menu__sub-icon"
                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </SideMenuTooltip>
              <!-- BEGIN: Second Child -->

              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.subMenu && menu.activeDropdown">
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subMenu"
                    :key="subMenuKey"
                  >
                  <div v-if="subMenu.roles.includes(userDetails.user_role)">
                    <SideMenuTooltip
                      tag="a"
                      :content="subMenu.title"
                      href="javascript:;"
                      class="side-menu"
                      :class="{ 'side-menu--active': subMenu.active }"
                      @click="linkTo(subMenu, router, $event)"
                    >
                      <div class="side-menu__title">
                        {{ subMenu.title }}
                        <span
                  class="text-xs px-1 rounded-full bg-theme-6 text-white mr-1 ml-2"
                  v-if="subMenu.title == 'Issue Transactions' && screenData.reqCount > 0"
                  >{{screenData.reqCount}}</span>
                  <span
                  class="text-xs px-1 rounded-full bg-theme-6 text-white mr-1 ml-2"
                  v-if="subMenu.title == 'List Issue(Lot/Packet)' && screenData.reqCountLot > 0"
                  >{{screenData.reqCountLot}}</span>
                        <div
                          v-if="subMenu.subMenu"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                        <li
                          v-for="(lastSubMenu,
                          lastSubMenuKey) in subMenu.subMenu"
                          :key="lastSubMenuKey"
                        >
                          <SideMenuTooltip
                            tag="a"
                            :content="lastSubMenu.title"
                            href="javascript:;"
                            class="side-menu"
                            :class="{ 'side-menu--active': lastSubMenu.active }"
                            @click="linkTo(lastSubMenu, router, $event)"
                          >

                            <div class="side-menu__title">
                              {{ lastSubMenu.title }}
                            </div>
                          </SideMenuTooltip>
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Third Child -->
                  </div>
                  </li>
                </ul>

              </transition>
              <!-- END: Second Child -->
              </div>
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>
      <nav class="side-nav side-nav--simple" :hidden="menuData">
        <!-- BEGIN: Logo -->
        <div
          class="intro-x flex items-center pl-5 pt-4"
        >
          <img
            alt="Icewall Tailwind HTML Admin Template"
            class="w-6"
            src="@/assets/images/logo.svg"
          />
      </div>
        <!-- END: Logo -->
        <div class="side-nav__devider my-6"></div>
        <button class="btn btn-link" @click="collapseMenu">Collaps</button>
        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li
              v-if="menu == 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-6"
            ></li>
            <li v-else :key="menu + menuKey">

              <div v-if="menu.roles.includes(userDetails.user_role)">
              <Tippy
                tag="a"
                :content="menu.title"
                :options="{
                  placement: 'left'
                }"
                :href="
                  menu.subMenu
                    ? 'javascript:;'
                    : router.resolve({ name: menu.pageName }).path
                "
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                @click="linkTo(menu, router, $event)"
              >
                <div class="side-menu__icon">
                  <component :is="menu.icon" />
                </div>
                <div class="side-menu__title">
                  {{ menu.title }}
                  <ChevronDownIcon
                    v-if="$h.isset(menu.subMenu)"
                    class="side-menu__sub-icon"
                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                  />
                </div>
              </Tippy>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="$h.isset(menu.subMenu) && menu.activeDropdown">
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subMenu"
                    :key="subMenuKey"
                  >
                  <div v-if="subMenu.roles.includes(userDetails.user_role)">
                    <Tippy
                      tag="a"
                      :content="subMenu.title"
                      :options="{
                        placement: 'left'
                      }"
                      :href="
                        subMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({ name: subMenu.pageName }).path
                      "
                      class="side-menu"
                      :class="{ 'side-menu--active': subMenu.active }"
                      @click="linkTo(subMenu, router, $event)"
                    >
                      <!-- <div class="side-menu__icon">
                        <ActivityIcon />
                      </div> -->
                      <div class="side-menu__title">
                        {{ subMenu.title }}
                        <ChevronDownIcon
                          v-if="$h.isset(subMenu.subMenu)"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown
                          }"
                        />
                      </div>
                    </Tippy>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul
                        v-if="
                          $h.isset(subMenu.subMenu) && subMenu.activeDropdown
                        "
                      >
                        <li
                          v-for="(lastSubMenu,
                          lastSubMenuKey) in subMenu.subMenu"
                          :key="lastSubMenuKey"
                        >
                          <Tippy
                            tag="a"
                            :content="lastSubMenu.title"
                            :options="{
                              placement: 'left'
                            }"
                            :href="
                              lastSubMenu.subMenu
                                ? 'javascript:;'
                                : router.resolve({ name: lastSubMenu.pageName })
                                    .path
                            "
                            class="side-menu"
                            :class="{ 'side-menu--active': lastSubMenu.active }"
                            @click="linkTo(lastSubMenu, router, $event)"
                          >
                            <div class="side-menu__icon">
                              <ZapIcon />
                            </div>
                            <div class="side-menu__title">
                              {{ lastSubMenu.title }}
                            </div>
                          </Tippy>
                        </li>
                      </ul>
                    </transition>
                  </div>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </transition>
              </div>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div class="content">
        <TopBar />
        <p v-if="userDetails.expiry_message">
          <span v-html="userDetails.expiry_message"></span>
        </p>
        <router-view />
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, computed, onMounted, ref,reactive, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { helper as $h } from '@/utils/helper'
import axios from 'axios'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
import { linkTo, nestedMenu, enter, leave } from './index'
import { BASE_URL } from '@/constants/config.js'

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    DarkModeSwitcher,
    SideMenuTooltip
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const formattedMenu = ref([])
    const sideMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    )

    const userDetails = computed(() => store.state.user.currentUser)
    const menuData = computed(() => store.state.user.sideMenuview)

    const collapseMenu = () => store.dispatch('sideMenuview')

    const screenData = reactive({
      reqCount: 0,
      reqCountLot:0
    })

    const checkRole = (roles,id) => {
      if(roles.indexOf(id) !== -1){
        return true;
      }
    }
    // const collapseMenu = () => {
    //   if(menuData.collaped){
    //     menuData.collaped = false;
    //   }else{
    //     menuData.collaped = true;
    //   }
    // }

    const getreqCount = () =>{

      let params =[];
      let promise = axios({
        url: "api/get_count_accept_request",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
        screenData.reqCount = data;
        })
        .catch(_error => {

        });

    }
    const getreqCountLot = () =>{
      let params =[];
      let promise = axios({
        url: "api/get_count_accept_request_lot",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
        screenData.reqCountLot = data;
        })
        .catch(_error => {

        });

    }
    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      }
    )

    onMounted(() => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .addClass('main')
      formattedMenu.value = $h.toRaw(sideMenu.value)

      // window.addEventListener('keydown', (e) => {
      //   if(e.keyCode === 73 && e.ctrlKey) {
      //     router.push("/app/app-process/kapan/issue/add");
      //   }else if(e.keyCode === 84 && e.ctrlKey) {
      //     router.push("/app/app-process/kapan/issue");
      //   }
      //   else if(e.keyCode === 80 && e.ctrlKey) {
      //     router.push("/app/app-process/packet/issue/add");
      //   }
      // });

      // getreqCount()
      // getreqCountLot()
    })

    return {
      formattedMenu,
      router,
      checkRole,
      linkTo,
      enter,
      leave,
      collapseMenu,
      userDetails,
      menuData,
      screenData
    }
  }

})
</script>
